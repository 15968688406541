import React from "react";
import Mention from '../mention/mention.js';
import Textmention from './text_mention.js';

const MentionLegales = () => {
    return (
        <div>
            <Textmention/>
            <Mention/>
        </div>
    );
};

export default MentionLegales;