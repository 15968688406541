import styled, { css } from 'styled-components';
import React from 'react';
import logo_matrice from '../../assets/matrice.png';
import logo_100 from '../../assets/100%.png';
import logo_diplome from '../../assets/diplome.png'
import logo_mentioning from '../../assets/mentoring.png';
import logo_running from '../../assets/running.png';
import Fade from 'react-reveal/Fade';


const Titrecarriere = () => {
    return (
        <div id="carriere">
            <TitleLeft style={{paddingTop: "100px"}}>
                <Fade top duration={2000}>
                    <Title color="white" main>UNE GESTION DE CARRIÈRE SUR-MESURE</Title>
                </Fade>
                <Fade left duration={2000}>
                    <Textsecond  style={{marginTop: "75px"}}>
                        Chaque membre de la communauté est directement rattaché aux cofondateurs.
                    </Textsecond>
                    <Textsecond  style={{maxWidth: "1000px"}}>
                        Nos métiers étant en constante évolution, nous mettons en place <span style={{fontWeight:"bold"}}>un dispositif de développement de carrière sur-mesure et à long terme.</span>
                    </Textsecond>
                    <Textsecond>
                        Cela se traduit par la mise en place :
                    </Textsecond>
                    <BlockIcon>
                        <img src={logo_matrice} height={53} width={53} alt=""/>
                        <BlockIconText>
                            d’une matrice de compétences individualisée.
                        </BlockIconText>
                    </BlockIcon>
                    <BlockIcon>
                        <img src={logo_diplome} height={53} width={53} alt=""/>
                        <BlockIconText >
                            de formations continues et d’accompagnements réguliers.
                        </BlockIconText>
                    </BlockIcon>
                    <BlockIcon >
                        <img src={logo_mentioning} height={53} width={53} alt=""/>
                        <BlockIconText>
                            de mentors issus des différents pôles d’expertises.
                        </BlockIconText>
                    </BlockIcon>
                    <BlockIcon>
                        <img src={logo_100} height={53} width={53} alt=""/>
                        <BlockIconText >
                            du choix des missions : <span style={{fontWeight:"bold"}}>100% des missions sont choisies par nos experts !</span>
                        </BlockIconText>
                    </BlockIcon>
                    <BlockIconlast>
                        <img src={logo_running} height={53} width={53} alt=""/>
                        <BlockIconText>
                            Chaque membre de la communauté peut participer activement au développement de 
                            la société au travers de projets internes allant même 
                            jusqu’à <span style={{fontWeight:"bold"}}>la création de sa propre entreprise</span> via notre startup studio.
                        </BlockIconText>
                    </BlockIconlast>
                </Fade>
            </TitleLeft>
        </div>
    );
};

const TitleLeft = styled.div`
  padding: 0px 13%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1300px) {
        width: 100%;
        text-align: center;
        text-align: center;
        padding: 0%;
    }
`
const Title = styled.div`
  ${props => props.main && css`
    font-size:55px;
    font-weight: 800;
  `}
  ${props => props.sub && css`
    font-size:20px;
    font-weight: 500;
    margin-top: 25px;
  `}
  ${props => props.color && css`
    color: ${color => props.color};
  `}
`

const Textsecond = styled.div`
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 20px;
    margin-top: 25px;
    @media screen and (max-width: 1300px) {
        font-size: 15px;
        text-align: center;
    }
    @media screen and (max-width: 700px) {
        margin-left: 5%;
        margin-right: 5%;
    }
`;

const BlockIcon = styled.div`
    color: white;
    font-size: 20px;
    margin-top: 25px;

    display: flex;
    align-items: center;
    @media screen and (max-width: 1300px) {
        font-size: 15px;
        text-align: center;
        margin-left: 5%;
    }
    @media screen and (max-width: 970px) {
        font-size: 15px;
        text-align: center;
        flex-direction: column;
        margin-left: 0;
    }
`
const BlockIconlast = styled.div`
    color: white;
    font-size: 20px;
    margin-top: 25px;

    display: flex;
    align-items: center;
    @media screen and (max-width: 1300px) {
        font-size: 15px;
        text-align: center;
        margin-left: 5%;
    }
    @media screen and (max-width: 1500px) {
        margin-bottom: 100px;
    }
    @media screen and (max-width: 970px) {
        font-size: 15px;
        text-align: center;
        flex-direction: column;
        margin-left: 0;
    }
    @media screen and (max-width: 900px) {
        margin-bottom: 150px;
    }
    @media screen and (max-width: 940px) {
        margin-left: 5%;
        margin-right: 5%;
    }
`

const BlockIconText = styled.div`
    margin-left: 25px;
    max-width: 1000px;
    @media screen and (max-width: 940px) {
        margin-left: 5%;
        margin-right: 5%;
    }
`


export default Titrecarriere;
