import React from "react";
import styled from 'styled-components';
import { keyframes, css } from 'styled-components'
import '../../../App.css';
import Vector from '../../../assets/Vector.png'
import Vector2 from '../../../assets/Vector2.png'


const Middle = () => {
    return (
        <div>
            <Middlepos>
                <Middletext>
                    NOTRE MISSION DÉPASSE
                    <br/>LA CRÉATION D’APPLICATIONS WEB & MOBILE
                </Middletext>
            </Middlepos>
            <Decouvrir>
                <a href="#expertise"><Decouvrirtext>Découvrir</Decouvrirtext></a>
                <a href="#expertise" aria-label="Arrow going down"><Arrow style={{width: '70px', height: '30px'}} alt="" src={Vector}/></a>
                <a href="#expertise" aria-label="Smaller arrow going down"><Arrow style={{width: '50px', height: '20px'}} alt="" src={Vector2}/></a>
            </Decouvrir>
        </div>
    );
};

const FirstArrowKey = keyframes`
    0% { margin-bottom: 20px; opacity: 0}
    50% { margin-bottom: 10px; opacity: 1}
    100% { margin-bottom: 0px;}
`
const SecondArrowKey = keyframes`
    0% { margin-bottom: 20px; opacity: 0}
    50% { margin-bottom: 10px; opacity: 1}
    100% { margin-bottom: 0px;}
`
const Arrow = styled.img `
    ${props => props.first && css`
        animation-name: ${FirstArrowKey};
        width: 50px;
        height: 20px;
    `}
    ${props => props.second && css`
        animation-name: ${SecondArrowKey};
        width: 35px;
        height: 13px;
    `}
    cursor:pointer;
    animation-duration: 1.2s;
    animation-timing-function: linear;
`;


const Decouvrir = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    bottom: 2rem;
    width: 100%;

`;

const MiddleposKey = keyframes`
    0% { bottom: 380px; opacity: 0}
    50% { bottom: 330px; opacity: 1}
    100% { bottom: 280px;}
`

const Middlepos = styled.div`
    position: absolute;
    bottom: 280px;
    margin-left: 10%;
    width: 75%;
    text-align: left;
    animation-name: ${MiddleposKey};
    animation-duration: 1.2s;
    animation-timing-function: linear;
    @media screen and (max-height: 650px) {
        bottom: 220px;
    }
    @media screen and (max-height: 550px) {
        bottom: 200px;
    }
`;


const Middletext = styled.h1`
    text-align: justify;
    text-decoration: none;
    color: white;
    flex-direction: column;
    font-size: 45px;
    font-family: 'Greycliff CF';
    text-align: left;
    @media screen and (max-width:1200px){
        font-size: 40px;
    }
    @media screen and (max-width:900px){
        font-size: 38px;
    }
`;

const DecouvrirtextKey = keyframes`
    0% { margin-bottom: 50px; opacity: 0}
    50% { margin-bottom: 40px; opacity: 1}
    100% { margin-bottom: 30px;}
`
const Decouvrirtext = styled.button`
    border: 2px solid white;
    font-size: 22px;
    padding: 15px 45px;
    border-radius: 10px;
    font-family: 'Ubuntu';
    background-color: white;
    margin-bottom: 30px;
    position: initial;
    cursor:pointer;
    animation-name: ${DecouvrirtextKey};
    animation-duration: 1.2s;
    animation-timing-function: linear;
`;

export default Middle;
