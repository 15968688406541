import styled from 'styled-components';
import React from 'react';
import double_quote_left from '../../assets/double_quote_left.png';
import double_quote_right from '../../assets/double_quote_right.png';
import { Slide } from 'react-slideshow-image';
import './css/slider_valeurs.css'

const properties = {
    duration: 4500,
    transitionDuration: 800,
    transition: 'opacity 50s ease-in',
    infinite: true,
    indicators: true,
    arrow: true,
}

const sliderText = [
    "Fais ce que tu veux, suis tes intuitions.\nMais quoi que tu fasses, fais le bien",
    "Le meilleur moyen de prédire l’avenir est de le créer",
    "Gardez vos yeux sur les étoiles et vos pieds sur terre",
    "Pivoter ce n'est pas se renier",
    "La vérité d'un jour n'est pas celle du lendemain",
    "La seule manière de faire du bon boulot, c'est d'aimer ce que tu fais",
    "En vérité, le chemin importe peu, la volonté suffit à tout",
    "Quand tout semble être contre toi, souviens-toi que l’avion décolle face au vent, et non avec lui",
    "Soyez vous-même, tous les autres sont déjà pris",
    "C’est dur d’échouer, mais c’est pire de n’avoir jamais essayé de réussir",
    "On a deux vies, et la deuxième commence lorsqu’on réalise qu’on en a qu’une",
];

const Slideshow = ({ config }) => {
  return (
    <Container style={{heigth: '150px'}}>
        <PosTitre>
                <TextTitle>
                    Nos mottos
                </TextTitle>
        </PosTitre>
        <PosDoublequoteLeft>
            <DoubleQuoteLeft src={double_quote_left} alt=""/>
        </PosDoublequoteLeft>
        <Slide {...properties}>
            {sliderText.map((item) => {
                return (
                <Slidertext>
                    <TextSize>
                        <Text>
                            {item}
                        </Text>
                    </TextSize>
                </Slidertext>
                );
            })}
        </Slide>
        <PosDoublequoteRight>
            <DoubleQuoteRight src={double_quote_right} alt=""/>
        </PosDoublequoteRight>
    </Container>
  )
}

const Container = styled.div `
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
`;


const PosTitre = styled.div`
    width: 100%;
    margin-top: 5%;
    text-align: center;
    position: relative;
`;

const Slidertext = styled.div`
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const TextSize = styled.div `
    max-width: 1000px;
    text-align: center;
`;

const PosDoublequoteLeft = styled.div `
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1100px) {
        justify-content: left;
    }
`;

const PosDoublequoteRight = styled.div `
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: right;
    position: sticky;
    @media (max-width: 1100px) {
        justify-content: right;
        width: 100%;
    }
`;

const DoubleQuoteLeft = styled.img`
    height: 41px;
    width: 70px;
`;

const DoubleQuoteRight = styled.img`
    height: 41px;
    width: 70px;
`;

const TextTitle = styled.text`
    text-decoration: none;
    text-align: justify;
    color: black;
    font-size: 40px;
    font-family: 'Creycliff CF';
`;


const Text = styled.div`
    text-decoration: none;
    text-align: center;
    color: black;
    font-size: 60px;
    font-family: 'Savoye LET';

    @media screen and (max-width: 1300px) {
        font-size: 66px;
    }

    @media screen and (max-width: 700px) {
        font-size: 46px;
    }
`;

export default Slideshow;
