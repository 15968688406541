import styled from 'styled-components';
import React from 'react'
import logo_meetups from '../../assets/meetups.png';
import logo_sponso from '../../assets/sponso.png';
import logo_agenda from '../../assets/agenda.png';
import logo_afterwork from '../../assets/afterwork.png'
import logo_team from '../../assets/team_building.png';
import Fade from 'react-reveal/Fade';

const TitleVie = () => {
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'Column',
        marginTop: '12rem',
        marginBottom: '12rem',
        alignItems: "center",
    }}>
        <Postitle>
            <Fade top duration={2000}>
                <Texttitle>NOTRE VIE INTERNE</Texttitle>
            </Fade>
            <Fade bottom duration={2000}>
                <Textsecond>
                    Nous organisons <span style={{fontWeight:"bold"}}>régulièrement</span> des évènements pour nous permettre de <span style={{fontWeight:"bold"}}>progresser</span> tout en développant notre réseau au sein de la communauté !
                </Textsecond>
            </Fade>
        </Postitle>
        <Posactivités>
            <Fade left duration={2000}>
                <Pose>
                    <Textbalise>
                        <img src={logo_meetups} height={71} width={71} alt=""/>
                    </Textbalise>
                    <Textbalise>
                        Meetups
                    </Textbalise>
                </Pose>
                <Pose>
                    <Textbalise>
                        <img src={logo_sponso} height={71} width={71} alt=""/>
                    </Textbalise>
                    <Textbalise>
                        Sponsoring de
                        <br/>conférences
                    </Textbalise>
                </Pose>
                <Pose>
                    <Textbalise>
                        <img src={logo_agenda} height={71} width={71} alt=""/>
                    </Textbalise>
                    <Textbalise>
                        Séminaires
                        <br/>Annuels
                    </Textbalise>
                </Pose>
                <Posejustify>
                    <Textbalise>
                        <img src={logo_afterwork} height={71} width={71} alt=""/>
                    </Textbalise>
                    <Textbalise>
                        Afterworks
                    </Textbalise>
                </Posejustify>
                <Posejustify>
                    <Textbalise>
                        <img src={logo_team} height={71} width={71} alt=""/>
                    </Textbalise>
                    <Textbalise>
                        Teambuilding
                    </Textbalise>
                </Posejustify>
            </Fade>
        </Posactivités>
    </div>
  )
}

// Text Position

const Postitle = styled.div `
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 950px;
    margin: 0 auto;
`;

const Posactivités = styled.div `
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 100px;
    grid-row-gap: 0px;
    width: 80%;

    @media screen and (max-width: 1220px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 50px;
    }

    @media screen and (max-width: 970px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 00px;
        grid-row-gap: 50px;
        text-align: center;
    }
`;

const Pose = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Posejustify = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1220px) {
        left: 12rem;
    }
    @media screen and (max-width: 970px) {
        left: 0rem;
    }
`;

// Text Police

const Texttitle = styled.h1`
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 70px;
    font-family: 'Greycliff CF';
    @media screen and (max-width: 1300px) {
        font-size: 40px;
    }
`;

const Textsecond = styled.h2`
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 20px;
    font-family: 'Nunito Sans';
    text-align: center;
    max-width: 790px;
    margin-top: 15px;
    @media screen and (max-width: 940px) {
        margin-left: 5%;
        margin-right: 5%;
    }
`;

const Textbalise = styled.p`
    text-decoration: none;
    text-align: center;
    color: white;
    font-size: 22px;
    font-family: 'Lora';
`;

export default TitleVie;
