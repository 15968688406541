import styled from 'styled-components';
import React from 'react';
import Titleexpertise from './titre_expertise';
import SlideshowPhone from './slider_phone';
import { createGlobalStyle } from 'styled-components';

const Globalstyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
}
`;

const config = [
  {
      title: 'logo_one',
      typeof: '*.png',
      image: 'https://i.postimg.cc/QthtNLBf/icone-black.jpg',
  },
  {
    title: 'logo_two',
    typeof: '*.png',
    image: 'https://i.postimg.cc/5tgcvjNW/logo-entreprise.png',
},
]

const Containerexpertise = styled.div`
  min-height:  100vh;
`;

const Expertise = () => {
    return (
      <Containerexpertise id="expertise">
        <Globalstyle/>
        <Titleexpertise/>
        <SlideshowPhone config={config}/>
      </Containerexpertise>
    );
};

export default Expertise;