import styled from 'styled-components';
import React from 'react';
import Fade from 'react-reveal/Fade';

const Titrevaleur = () => {
    return (
        <Fade top duration={2000}>
            <Postitle>
                    <Texttitle>NOS VALEURS</Texttitle>
            </Postitle>
        </Fade>
    );
};

const Texttitle = styled.h1`
    text-decoration: none;
    color: black;
    font-size: 70px;
    font-family: 'Greycliff CF';
`;

const Postitle = styled.div`
    position: relative;
    margin-left: 13%;
    margin-top: 5%;

    @media screen and (max-width: 1300px) {
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
`;

export default Titrevaleur;
