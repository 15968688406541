import styled, { keyframes } from 'styled-components';
import React from "react";
import Page from './page';

const ContainerMentionKey = keyframes`
0% {
    background-position: 0 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0 50%;
}
`
const Containermention = styled.div`
  min-height:  15vh;
  background: linear-gradient(270deg,#4338B4,#7E46AD,#AC50AA,#CF58A8);
  background-color: #CF58A8;
  background-size: 1000%;
  background-repeat: repeat;
  color: #fff;
  animation: j 10s linear infinite forwards;
  animation-name:${ContainerMentionKey}
`;

const Mention = () => {
    return (
      <div>
        <Containermention>
          <Page/>
        </Containermention>
      </div>
    );
};

export default Mention;