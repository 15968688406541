import styled from 'styled-components';
import React from 'react';
import background from '../../assets/identité2.webp';
import './css/identite.css';
import Fade from 'react-reveal/Fade';

const Containeridentités= styled.div`
    margin-top: 2%;
    min-height:  140vh;
    display: flex;
    justify-content:center;
    align-items: baseline;
    background-image: url(${background});
    background-size: cover; /* L'image prendra 100% de l'espace, quitte à la crop */
    background-position: center; /* Centre la position de l'image */
    @media screen and (min-width: 1330px) {
        display: none;
    }
`;

const Identités = () => {
    return (
         <Containeridentités id="container-identite">
            <Container id="container-text">
                    <ContainerText>
                        <Postitle>
                        <Fade up duration={2000}>
                            <Texttitle id="identityphone">NOTRE IDENTITÉ</Texttitle>
                        </Fade>
                        </Postitle>
                        <Postext>
                            <Fade up duration={2000}>
                                <Text>
                                    Nous pensons que la technologie est porteuse de
                                    progrès à partir du moment où nous plaçons l'Humain
                                    au cœur du processus.
                                </Text>
                            </Fade>
                        </Postext>
                        <Fade up duration={2000}>
                            <Postextsecond>
                                    <Textsecond>
                                        Inspiire accompagne des startups, des PME et des grandes entreprises à concevoir,
                                         réaliser et délivrer les meilleures solutions digitales du marché.
                                    </Textsecond>
                            </Postextsecond>
                            <Postexttest>
                                    <Textsecond>
                                    Inspiire est avant tout une aventure humaine et entrepeneuriale. Nous avons
                                    choisi de construire une société par et pour nos collaborateurs.
                                    </Textsecond>
                            </Postexttest>
                        </Fade>
                        <Postextthird>
                            <Fade up duration={2000}>
                                <Textthird>
                                    Une entreprise au sein de laquelle les initiatives, la
                                    bienveillance, la quête de sens sont encouragées.
                                </Textthird>
                            </Fade>
                        </Postextthird>
                        <Postextfourth>
                            <Fade up duration={2000}>
                                <Textsecond>
                                    Avec la création de notre startup studio, chaque membre de la communauté peut
                                    devenir manager de sa société spécialisée dans des domaines qui l'inspirent.
                                </Textsecond>
                            </Fade>
                        </Postextfourth>
                    </ContainerText>
            </Container>
        </Containeridentités>
    );
};

const Container = styled.div`
    width: 100%;
    bottom: 20vh;
`;

const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    @media screen and (max-width: 860px) {
        margin-left: -5%;
        margin-right: 5%;
    }
`;

const Postitle = styled.div`
    position: relative;
    @media screen and (max-width: 1250px) {
        margin-top: 25%;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 35%;
    }
    @media screen and (max-width: 800px) {
        margin-top: 45%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 55%;
    }
    margin-top: 20%;
    margin-left: 10.5%;
`;


const Postext = styled.div`
    position: relative;
    margin-top: 4%;
    margin-left: 10.5%;
    max-width: 600px;
    @media screen and (max-width: 1250px) {
        margin-top: 4%;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 6%;
    }
    @media screen and (max-width: 800px) {
        margin-top: 8%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 10%;
    }
`;

const Postextsecond = styled.div`
    position: relative;
    margin-top: 4%;
    margin-left: 10.5%;
    max-width: 780px;
    @media screen and (max-width: 1250px) {
        margin-top: 4%;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 6%;
    }
    @media screen and (max-width: 800px) {
        margin-top: 8%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 10%;
    }
`;

const Postexttest = styled.div`
    margin-left: 10.5%;
    max-width: 750px;
`;

const Postextthird= styled.div`
    position: relative;
    margin-top: 2%;
    margin-left: 10.5%;
    max-width: 650px;
    @media screen and (max-width: 1250px) {
        margin-top: 4%;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 6%;
    }
    @media screen and (max-width: 800px) {
        margin-top: 8%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 10%;
    }
`;

const Postextfourth= styled.div`
    position: relative;
    margin-top: 2%;
    margin-left: 10.5%;
    max-width: 750px;
    @media screen and (max-width: 1000px) and (max-height: 900px) {
        margin-bottom: 100px;
    }
`;

const Texttitle = styled.h1`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 70px;
    font-family: 'Greycliff CF';
`;

const Text = styled.p`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Greycliff CF';
`;

const Textsecond = styled.p`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 20px;
    font-family: 'Nunito Sans';
    font-weight: 700;
`;

const Textthird = styled.p`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 24px;
    font-family: 'Greycliff CF';
    font-weight: 800;
`;


export default Identités;
