import styled from 'styled-components';
import React from 'react';
import Titrecarriere from './titre_carriere';
import background from '../../assets/gestion_de_carriere_fond.webp';

const Containercarriere= styled.div`
    position: relative;
    min-height:  100vh;
    background-image: url(${background});
    background-size: cover;
    background-attachment: fixed;
    clip-path: polygon(14% 100%,100% 92%,100% 0,0 0,0 93%);
    z-index:1;
`;

const Carriere = () => {
    return (
        <Containercarriere id="carrer">
            <Titrecarriere/>
        </Containercarriere>
    );
};

export default Carriere;
