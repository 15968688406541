import styled from 'styled-components';
import React from "react";
// import logo from '../../assets/logo2.png';
import logo from '../../assets/logo2.png';

import linkedin from '../../assets/whitelinkedin.webp';
import { Link } from 'react-router-dom';


const Page = () => {
    return (
        <FlexDiv>
            <PosLogo>
                <a href='#navbar' aria-label="Logo Inspiire" alt=""><img src={logo} width={100} height={30.75} alt=""/></a>
            </PosLogo>
            <Textmention fontSize="22px" style={{
                textAlign: 'center',
            }}>© Copyright Inspiire 2024 - <Link to="/mention-legales/" style={{textDecoration: 'none', color: 'white'}}>Mentions légales</Link>
                <br/><Textmentionsecond>designed and developped with 💜 by</Textmentionsecond>
                <Textmentionsecondbold href='https://www.linkedin.com/in/leonie-coulandreau/'> CLEO Graphic Design</Textmentionsecondbold>
                <Textmentionsecond> & </Textmentionsecond> <Textmentionsecondbold href='https://www.grafenit.com/'>Grafen_it</Textmentionsecondbold>
            </Textmention>
            <a style={{
                textAlign: 'center',
                textDecoration: 'none',
                position: 'relative',
                marginTop: '2%',
            }}  href="https://www.linkedin.com/company/inspiire-fr/about/?viewAsMember=true">
                <Textmention fontSize="18px" weight="600">Nous suivre</Textmention>
                <br/>
                <img src={linkedin} height={45} width={45} alt=""/>
            </a>
        </FlexDiv>
    );
};

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-around;
    height: 100%;
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
    @media screen and (max-width: 550px) {
        margin-right: 5%;
        margin-left: 5%;
    }
`

const PosLogo = styled.div`
    @media screen and (max-width: 700px) {
        margin-top: 20px;
        margin-bottom: -20px;
    }
`

const Textmention = styled.text`
    font-weight:${ props => props.weight };
    margin-top: 2%;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: ${props => props.fontSize};
    font-size: 18px;
    font-family: 'Nunito Sans', sans-serif;
    @media screen and (max-width: 1300px) {
        margin-top: 7%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 10%;
    }
`;

const Textmentionsecond = styled.text`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 18px;
    font-family: 'Nunito Sans';
    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;

const Textmentionsecondbold = styled.a`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    font-weight: bold;
    color: white;
    font-size: 18px;
    font-family: 'Nunito Sans';
    @media screen and (max-width: 800px) {
        font-size: 16px;
    }
`;

export default Page;
