import React from 'react';
import './css/identite.css';
import IdentitésPhone from './page_identités_phone';
import AnimationIdentités from './page_identités';

const Identités = () => {
    return (
        <div>
            <AnimationIdentités/>
            <IdentitésPhone/>
        </div>
    );
};

export default Identités;
