import styled from 'styled-components';
import React from 'react'
import logo1 from '../../assets/logos couleurs/react.webp';
import logo2 from '../../assets/logos couleurs/Node-js-logo-c.png';
import logo3 from '../../assets/logos couleurs/logo-js-c.png';
import logo4 from '../../assets/logos couleurs/logo-ts-c.png';
import logo5 from '../../assets/logos couleurs/symfony.png';
import logo6 from '../../assets/logos couleurs/logos-v-c.png';
import logo7 from '../../assets/logos couleurs/grapgqs-c.png';
import logo8 from '../../assets/logos couleurs/pwa-c.png';
import logo9 from '../../assets/logos couleurs/logo-nest-c.png';
import logo10 from '../../assets/logos couleurs/logo-flutter-c.webp';
import logo11 from '../../assets/logos couleurs/git-c.png';
import logo12 from '../../assets/logos couleurs/apple.png';
import logo13 from '../../assets/logos couleurs/android-c.png';
import logo14 from '../../assets/logos couleurs/kotlin-c.webp';
import logo15 from '../../assets/logos couleurs/swift.webp';
import logo16 from '../../assets/logos couleurs/cloud-c.png';
import logo17 from '../../assets/logos couleurs/aws-c.webp';
import logo18 from '../../assets/logos couleurs/a-c.png';
import logo19 from '../../assets/logos couleurs/docker.png';
import logo20 from '../../assets/logos couleurs/kubernetes-c.webp';

import logo21 from '../../assets/Logos noirs et blancs/react-nb.png';
import logo22 from '../../assets/Logos noirs et blancs/node-nb.png';
import logo23 from '../../assets/Logos noirs et blancs/js-nb.png';
import logo24 from '../../assets/Logos noirs et blancs/ts-nb.png';
import logo25 from '../../assets/Logos noirs et blancs/symfony.png';
import logo26 from '../../assets/Logos noirs et blancs/v-nb.png';
import logo27 from '../../assets/Logos noirs et blancs/grapgqs-nb.png';
import logo28 from '../../assets/Logos noirs et blancs/pxa-nb.png';
import logo29 from '../../assets/Logos noirs et blancs/nest-nb.png';
import logo30 from '../../assets/Logos noirs et blancs/flutter-nb.png';
import logo31 from '../../assets/Logos noirs et blancs/git-nb.png';
import logo32 from '../../assets/Logos noirs et blancs/apple.png';
import logo33 from '../../assets/Logos noirs et blancs/android-nb.png';
import logo34 from '../../assets/Logos noirs et blancs/kotlin-nb.png';
import logo35 from '../../assets/Logos noirs et blancs/swift-nb.png';
import logo36 from '../../assets/Logos noirs et blancs/cloud-nb.png';
import logo37 from '../../assets/Logos noirs et blancs/aws-nb.png';
import logo38 from '../../assets/Logos noirs et blancs/a-c.png';
import logo39 from '../../assets/Logos noirs et blancs/docker-nb.png';
import logo40 from '../../assets/Logos noirs et blancs/kubernetes.png';
import { Slide } from 'react-slideshow-image';
import { useEffect } from 'react';
import Aos from 'aos';
import './css/slider.css';

const properties = {
    duration: 100000,
    transitionDuration: 600,
    transition: 'opacity 50s ease-in',
    infinite: true,
    indicators: true,
    arrow: false,
}


const Slideshow = ({ config }) => {
    useEffect(() => {
        Aos.init({duration: 2000});
    }, [])
  return (
    <Container data-aos='fade-down'>
        <Posslider>
            <Slide {...properties}>
                <Slidertext className='img-wrapper'>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo1} onMouseOver={e => e.currentTarget.src = logo21}
                        onMouseOut={e => e.currentTarget.src = logo1}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo2} onMouseOver={e => e.currentTarget.src = logo22}
                        onMouseOut={e => e.currentTarget.src = logo2}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo3} onMouseOver={e => e.currentTarget.src = logo23}
                        onMouseOut={e => e.currentTarget.src = logo3}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo4} onMouseOver={e => e.currentTarget.src = logo24}
                        onMouseOut={e => e.currentTarget.src = logo4}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo5} onMouseOver={e => e.currentTarget.src = logo25}
                        onMouseOut={e => e.currentTarget.src = logo5}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo6} onMouseOver={e => e.currentTarget.src = logo26}
                        onMouseOut={e => e.currentTarget.src = logo6}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo7} onMouseOver={e => e.currentTarget.src = logo27}
                        onMouseOut={e => e.currentTarget.src = logo7}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo8} onMouseOver={e => e.currentTarget.src = logo28}
                        onMouseOut={e => e.currentTarget.src = logo8}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo9} onMouseOver={e => e.currentTarget.src = logo29}
                        onMouseOut={e => e.currentTarget.src = logo9}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo10} onMouseOver={e => e.currentTarget.src = logo30}
                        onMouseOut={e => e.currentTarget.src = logo10}/>
                    </div>
                </Slidertext>
                <Slidertext>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo11} onMouseOver={e => e.currentTarget.src = logo31}
                        onMouseOut={e => e.currentTarget.src = logo11}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo12} onMouseOver={e => e.currentTarget.src = logo32}
                        onMouseOut={e => e.currentTarget.src = logo12}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo13} onMouseOver={e => e.currentTarget.src = logo33}
                        onMouseOut={e => e.currentTarget.src = logo13}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo14} onMouseOver={e => e.currentTarget.src = logo34}
                        onMouseOut={e => e.currentTarget.src = logo14}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo15} onMouseOver={e => e.currentTarget.src = logo35}
                        onMouseOut={e => e.currentTarget.src = logo15}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo16} onMouseOver={e => e.currentTarget.src = logo36}
                        onMouseOut={e => e.currentTarget.src = logo16}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo17} onMouseOver={e => e.currentTarget.src = logo37}
                        onMouseOut={e => e.currentTarget.src = logo17}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo18} onMouseOver={e => e.currentTarget.src = logo38}
                        onMouseOut={e => e.currentTarget.src = logo18}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo19} onMouseOver={e => e.currentTarget.src = logo39}
                        onMouseOut={e => e.currentTarget.src = logo19}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo20} onMouseOver={e => e.currentTarget.src = logo40}
                        onMouseOut={e => e.currentTarget.src = logo20}/>
                    </div>
                </Slidertext>
            </Slide>
        </Posslider>
        <Posslidersecond>
            <Slide {...properties}>
                <Slidertext>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo1} onMouseOver={e => e.currentTarget.src = logo21}
                        onMouseOut={e => e.currentTarget.src = logo1}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo2} onMouseOver={e => e.currentTarget.src = logo22}
                        onMouseOut={e => e.currentTarget.src = logo2}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo3} onMouseOver={e => e.currentTarget.src = logo23}
                        onMouseOut={e => e.currentTarget.src = logo3}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo4} onMouseOver={e => e.currentTarget.src = logo24}
                        onMouseOut={e => e.currentTarget.src = logo4}/>
                    </div>
                </Slidertext>
                <Slidertext>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo5} onMouseOver={e => e.currentTarget.src = logo25}
                        onMouseOut={e => e.currentTarget.src = logo5}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo6} onMouseOver={e => e.currentTarget.src = logo26}
                        onMouseOut={e => e.currentTarget.src = logo6}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo7} onMouseOver={e => e.currentTarget.src = logo27}
                        onMouseOut={e => e.currentTarget.src = logo7}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo8} onMouseOver={e => e.currentTarget.src = logo28}
                        onMouseOut={e => e.currentTarget.src = logo8}/>
                    </div>
                </Slidertext>
                <Slidertext>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo9} onMouseOver={e => e.currentTarget.src = logo29}
                        onMouseOut={e => e.currentTarget.src = logo9}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo10} onMouseOver={e => e.currentTarget.src = logo30}
                        onMouseOut={e => e.currentTarget.src = logo10}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo11} onMouseOver={e => e.currentTarget.src = logo31}
                        onMouseOut={e => e.currentTarget.src = logo11}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo12} onMouseOver={e => e.currentTarget.src = logo32}
                        onMouseOut={e => e.currentTarget.src = logo12}/>
                    </div>
                </Slidertext>
                <Slidertext>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo13} onMouseOver={e => e.currentTarget.src = logo33}
                        onMouseOut={e => e.currentTarget.src = logo13}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo14} onMouseOver={e => e.currentTarget.src = logo34}
                        onMouseOut={e => e.currentTarget.src = logo14}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo15} onMouseOver={e => e.currentTarget.src = logo35}
                        onMouseOut={e => e.currentTarget.src = logo15}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo16} onMouseOver={e => e.currentTarget.src = logo36}
                        onMouseOut={e => e.currentTarget.src = logo16}/>
                    </div>
                </Slidertext>
                <Slidertext>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo17} onMouseOver={e => e.currentTarget.src = logo37}
                        onMouseOut={e => e.currentTarget.src = logo17}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo18} onMouseOver={e => e.currentTarget.src = logo38}
                        onMouseOut={e => e.currentTarget.src = logo18}/>
                    </div>
                    <div>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo19} onMouseOver={e => e.currentTarget.src = logo39}
                        onMouseOut={e => e.currentTarget.src = logo19}/>
                        <img alt="" loading="lazy" className='hover-zoom imgphone-slider' src={logo20} onMouseOver={e => e.currentTarget.src = logo40}
                        onMouseOut={e => e.currentTarget.src = logo20}/>
                    </div>
                </Slidertext>
            </Slide>
        </Posslidersecond>
    </Container>
  )
}

const SlideshowPhone = () => {

  return (
        <Slideshow/>
  )
}

const Container = styled.div`
    /* display: none; */
    @media (max-width: 1300px) {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        display: flex;
    }
    @media (max-width: 900px) {
        align-items: center;
    }
`;

const Posslider = styled.div`
    max-width: 100%;
    @media (max-width: 900px) {
        display: none;
    }
`;

const Posslidersecond = styled.div`
    max-width: 100%;
    @media (min-width: 900px) {
        display: none;
    }
`;

const Slidertext = styled.div`
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
`;

export default SlideshowPhone;