import styled from 'styled-components';
import React from 'react';

import background from '../../assets/identité2.webp';
import {useEffect} from 'react';
import './css/identite.css';

import {fixElement} from '../utils/functions/fixElement';


const Containeridentités= styled.div`
    margin-top: 2%;
    min-height:  300vh;
    /* min-height:  140vh; */
    display: flex;
    justify-content:center;
    align-items: baseline;
    background-image: url(${background});
    background-attachment: fixed; /* Fixe l'image pendant le scroll */
    background-size: cover; /* L'image prendra 100% de l'espace, quitte à la crop */
    background-position: center; /* Centre la position de l'image */
    background-repeat: no-repeat;
    position: sticky;
    @media screen and (max-width: 1330px) {
        display: none;
    }
`;




const defautCircleHeight = 150;

const AnimationIdentités = () => {
    useEffect(() => {
        const handleScroll = event => {
            const circle_svg = document.getElementById("circle-hole");
            const container_identite = document.getElementById("container-identite");
            const container_text = document.getElementById("container-text");
            const pos = container_identite.getBoundingClientRect();
            if (pos.top < 0 && pos.bottom >= 700) // >= 700 pour stopper la croissance du rond qui bloque l'utilisation de celui-ci plus tard
            {
                fixElement(true, "div-svg");
                circle_svg.setAttribute('r', defautCircleHeight + pos.top * -1); // increase circle radius
            }else{
                fixElement(false, "div-svg");
                container_text.style.display = "none"; // display none late to see the fade-out-bottom and none to see the fade-in animation
            }
            if(pos.bottom < 1500) // we are a the end of circle animation (radius increase)
            {
                container_text.classList.remove("fade-out-bottom");  //remove text exit animation
                container_text.style.display = "block"; //display text
            }
            else
                container_text.classList.add("fade-out-bottom"); //add exit animation because of back scroll

            if(pos.bottom < 1000) //end of container
            {
                container_text.style.position = "absolute"; //fix the text to the container 
                fixElement(false, "div-svg"); // unfix circle for let the circle in the container
            }
            else
                container_text.style.position = "fixed";

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
         <Containeridentités id="container-identite">
            <div style={{width: '100%'}} id="div-svg">
                <svg style={{width:"100%", height:"100vh"}}>
                    <mask id="hole">
                        <rect width="100vw" height="100%" fill="white"/>
                        <circle id="circle-hole" r="150" cx="50vw" cy="32%" fill="black"/>
                    </mask>
                    <rect width="100vw" height="100%" mask="url(#hole)" fill='white'/>
                </svg>
            </div>
            <div style={{position:"absolute", bottom:"100vh"}} id="identity"></div>
            <Container id="container-text">
                <ContainerText>
                    <Postitle className='fade-in-bottom1'>
                        <Texttitle>NOTRE IDENTITÉ</Texttitle>
                    </Postitle>
                    <Postext className='fade-in-bottom2'>
                        <Text>
                            Nous pensons que la technologie est porteuse de
                            <br/>progrès à partir du moment où nous plaçons l'Humain
                            <br/>au cœur du processus.
                        </Text>
                    </Postext>
                    <Postextsecond className='fade-in-bottom3'>
                        <Textsecond>
                            Inspiire accompagne des startups, des PME et des grandes entreprises à concevoir,
                            <br/> réaliser et délivrer les meilleures solutions digitales du marché.
                            <br/>Inspiire est avant tout une aventure humaine et entrepeneuriale. Nous avons
                            <br/>choisi de construire une société par et pour nos collaborateurs.
                        </Textsecond>
                    </Postextsecond>
                    <Postextthird className='fade-in-bottom4'>
                        <Textthird>
                            Une entreprise au sein de laquelle les initiatives, la
                            <br/>bienveillance, la quête de sens sont encouragées.
                        </Textthird>
                    </Postextthird>
                    <Postextfourth className='fade-in-bottom5'>
                        <Textsecond>
                            Avec la création de notre startup studio, chaque membre de la communauté peut
                            <br/>devenir manager de sa société spécialisée dans des domaines qui l'inspirent.
                        </Textsecond>
                    </Postextfourth>
                </ContainerText>
            </Container>
        </Containeridentités>
    );
};

const Container = styled.div`
    display: none;
    width: 100%;
    bottom: 20vh;
    position: fixed;
`;

const ContainerText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
`;

const Postitle = styled.div`
    position: relative;
    @media screen and (max-width: 1250px) {
        margin-top: 25%;
    }
    @media screen and (max-width: 1000px) {
        margin-top: 35%;
    }
    @media screen and (max-width: 800px) {
        margin-top: 45%;
    }
    @media screen and (max-width: 600px) {
        margin-top: 55%;
    }
    margin-top: 20%;
    margin-left: 10.5%;
`;

const Postext = styled.div`
    position: relative;
    margin-top: 4%;
    margin-left: 10.5%;
`;

const Postextsecond = styled.div`
    position: relative;
    margin-top: 4%;
    margin-left: 10.5%;
`;

const Postextthird= styled.div`
    position: relative;
    margin-top: 2%;
    margin-left: 10.5%;
`;

const Postextfourth= styled.div`
    position: relative;
    margin-top: 2%;
    margin-left: 10.5%;
`;

const Texttitle = styled.h1`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 70px;
    font-family: 'Greycliff CF';
`;

const Text = styled.p`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Greycliff CF';
`;

const Textsecond = styled.p`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 20px;
    font-family: 'Nunito Sans';
    font-weight: 700;
`;

const Textthird = styled.p`
    cursor: pointer;
    text-decoration: none;
    text-align: justify;
    color: white;
    font-size: 24px;
    font-family: 'Greycliff CF';
    font-weight: 800;
`;


export default AnimationIdentités;
