import styled, { keyframes, createGlobalStyle } from 'styled-components';
import Navbar from '../../components/reception/container/navbar.js';
import Title from '../../components/reception/container/title.js';
import video from '../../assets/film.mp4';
import Montagne from '../../assets/fond_montagne.webp';

const Reception = () => {
  return (
    <ContainerReception id="accueil">
      <video src={video} muted autoPlay playsInline loop>
        <source src={video} type="video/webm"/>
          Your browser does not support the video tag.
      </video>
      <Globalstyle/>
      <Navbar/>
      <Title/>
      <Nextpage/>
      <BlackPolygon/>
    </ContainerReception>
  );
};

const BlackPolygon = styled.div`
    background: white;
    width: 100%;
    height: 100.05%;
    clip-path: polygon(85% 91%, 0% 100%, 100% 100%);
`
const Nextpage = styled.div`
  width: 1%;
  height: 1%;
  position: absolute;
  top: 90%;
`;

const Globalstyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
}
`;

const ContainerFirstPageKey = keyframes`
    0% { background-position: center 80%;}
    100% { background-position: center center;}
    `

const ContainerReception = styled.div`
  height:  110vh;
  display: block;
  animation-name: ${ContainerFirstPageKey};
  animation-duration: 1.2s;
  animation-timing-function: linear;
  @media screen and (max-width: 1200px) {
    background-image: url(${Montagne});
    background-size: cover;
  }
`;
export default Reception;
