import styled from 'styled-components';
import React from 'react';
import arbre from '../../assets/arbre.webp';
import Fade from 'react-reveal/Fade';
// import associations from '../../assets/Raison_detre/associations-partenaires.png';
// import collaborateurs from '../../assets/Raison_detre/bien-etre-collaborateurs.png';
// import carbone from '../../assets/Raison_detre/bilan-carbone-positif.png';
// import formation from '../../assets/Raison_detre/formation-environnement.png';
// import associations2 from '../../assets/Raison_detre/associations-partenaires2.png';
// import collaborateurs2 from '../../assets/Raison_detre/bien-etre-collaborateurs2.png';
// import carbone2 from '../../assets/Raison_detre/bilan-carbone-positif2.png';
// import formation2 from '../../assets/Raison_detre/formation-environnement2.png';
import associations3 from '../../assets/Raison_detre/associations-degrade3.webp';
import collaborateurs3 from '../../assets/Raison_detre/bien-etre-degrade3.webp';
import carbone3 from '../../assets/Raison_detre/bilan-carbone-degrade3.webp';
import formation3 from '../../assets/Raison_detre/formations-degrade3.webp';


const Rectangle_raison = () => {

    return (
        <div style={{display: "flex", overflow: 'hidden', minHeight: '700px'}}>
            <Block>
                <Fade up duration={2000}>
                    <Rectangle>
                        <img alt="" height={122} width={122} src={carbone3}/>
                        <Text>Avoir un bilan carbone positif</Text>
                    </Rectangle>
                    <Rectangle>
                        <PosImage>
                            <img alt="" height={122} width={122} src={collaborateurs3}/>
                        </PosImage>
                        <Text>Favoriser une organisation permettant de préserver le bien-être de ses collaborateurs</Text>
                    </Rectangle>
                    <Rectangle>
                        <PosImagesecond>
                            <img alt="" height={122} width={122} src={formation3}/>
                        </PosImagesecond>
                        <Text>Avoir 100% de nos collaborateurs sensibilisés aux questions d’impact environnemental</Text>
                    </Rectangle>
                    <Rectangle>
                        <img alt="" height={122} width={122} src={associations3}/>
                        <Text>Soutenir des associations défendant le droit humain et la biodiversité</Text>
                    </Rectangle>
                </Fade>
            </Block>
            <BlockImage>
                <Fade right duration={2000}>
                    <Posarbre  style={{opacity: 1}}/>
                </Fade>
            </BlockImage>
        </div>
    );
};

const Block = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
    width: 650px;
    padding-left: 6%;
    margin-bottom: 100px;
    flex: 1;

    @media (max-width: 800px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 0;
        grid-row-gap: 50px;
        padding-top: 50px;
    }
`

const PosImage = styled.div `
    margin-top: 60px;
    @media (max-width: 800px) {
        margin-top: 0;
    }
`

const PosImagesecond = styled.div `
    margin-top: 30px;
    @media (max-width: 800px) {
        margin-top: 0;
    }
`

const Text = styled.p`
    color: black;
    font-size: 20px;
    font-family: 'Nunito sans';
`

const BlockImage = styled.div`
    position: relative;
    flex: 1;
    @media (max-width: 1250px) {
        display: none;
    }
`

const Rectangle = styled.div`
    display: flex;
    width: 350px;
    height: 155px;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    font-size: 20px;
    padding: 20px;
    padding-bottom: 0;
    @media (max-width: 1250px) {
        padding-left: 0;
    }
    @media (max-width: 800px) {
        padding-bottom: 20px;
    }
`;

const Posarbre = styled.div`
    position: absolute;
    right: 0px;
    width: 100%;
    left: 135px;
    height: 1055px;
    top: -48px;
    background-image: url(${arbre});
    background-size: cover;

    @media (max-width: 1450px) {
        width: 95%;
        height: 955px;
    }
    @media (max-width: 1350px) {
        width: 90%;
        height: 955px;
    }
    @media (max-width: 1250px) {
        display: none;
    }
`;

export default Rectangle_raison;
