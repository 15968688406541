import styled from 'styled-components';
import React from 'react';
import logo_equipe from '../../assets/valeur_esprit.png';
import logo_diversite from '../../assets/valeurs_diversite.png';
import logo_plaisir from '../../assets/valeurs_plaisir.png';
import logo_humilite from '../../assets/valeurs_humilite.png'
import Fade from 'react-reveal/Fade';


const Logovaleurs = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Container>
                <Fade left duration={2000}>
                    <Pose>
                        <img src={logo_equipe} height={81} width={135} alt=""/>
                        <TitleLogo>L'Esprit d'Equipe</TitleLogo>
                        <p style={{textAlign: 'center'}}>
                            Nous encourageons la
                            bienveillance, l’honnêteté,
                            la confiance, le partage et
                            l'entraide pour réussir
                            ensemble.
                        </p>
                    </Pose>
                    <Pose>
                        <img src={logo_diversite} height={81} width={81} alt=""/>
                        <TitleLogo>La Diversité</TitleLogo>
                        <p style={{textAlign: 'center'}}>
                            Nous pensons que la
                            diversité est une richesse
                            pour l’entreprise et la
                            société et nous prônons
                            l'égalité des chances et
                            l'inclusion.
                        </p>
                    </Pose>
                    <Pose>
                        <img src={logo_plaisir} height={81} width={81} alt=""/>
                        <TitleLogo>Le Plaisir</TitleLogo>
                        <p style={{textAlign: 'center'}}>
                            Nous favorisons le fait
                            d’être fier de ce que l’on
                            fait, de se sentir bien dans
                            l’entreprise et de ne pas
                            oublier de prendre le
                            temps de passer
                            régulièrement de bons
                            moments ensemble.
                        </p>
                    </Pose>
                    <Pose>
                        <img src={logo_humilite} height={81} width={81} alt=""/>
                        <TitleLogo>L'Humilité</TitleLogo>
                        <p style={{textAlign: 'center'}}>
                            Nous préconisons l’écoute,
                            la simplicité, la modestie,
                            la capacité de se remettre
                            en question et de
                            comprendre les autres, les
                            cultures, les coutumes et
                            son environnement.
                        </p>
                    </Pose>
                </Fade>
            </Container>
        </div>
    );
};

const TitleLogo = styled.p`
    font-weight: bold;
    text-align: center;
    margin: 15px 0;
`

const Container = styled.div `
    margin-top: 5%;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 100px;
    grid-row-gap: 0px;

    @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 50px;
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 00px;
        grid-row-gap: 50px;
        text-align: center;
    }
`;

const Pose = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export default Logovaleurs;
