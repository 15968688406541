import styled from 'styled-components';
import React from 'react'
import Franck_black from '../../assets/franck_black_rond.webp';
import Franck_couleurs from '../../assets/franck_couleur_rond.webp';
import Nicolas_couleurs from '../../assets/nicolas_couleur_rond.webp';
import Nicolas_black from '../../assets/nicolas_black_rond.webp';
import Linkedin_Franck from '../../assets/linkedin_franck.png';
import Linkedin_Nicolas from '../../assets/linkedin_nicolas.png';
import Fade from 'react-reveal/Fade';

import './css/transition.css';

const Patron = () => {
    return (
      <Container>
        <Fade left duration={2000}>
            <Profil>
                <Align>
                    <Img  className='hover-zoom-patron' src={Franck_couleurs} onMouseOver={e => e.currentTarget.src = Franck_black}
                    onMouseOut={e => e.currentTarget.src = Franck_couleurs} alt=""/>
                    <Info>
                        <p>Franck Marty</p>
                        <p>Co-fondateur</p>
                        <a style={{textDecoration: 'none', color:'inherit'}} href="tel:0662069897"><p>Tel : 06 62 06 98 97</p></a>
                        <a style={{textDecoration: 'none', color:'inherit'}} href='mailto:fmarty@inspiire.fr'><p>Mail : fmarty@inspiire.fr</p></a>
                        <a style={{marginTop: "10px"}} aria-label="Linkedin Franck" href="https://www.linkedin.com/in/franck-marty-7596142a/">
                            <img src={Linkedin_Franck} height={50} width={50} alt=""/>
                        </a>
                    </Info>
                </Align>
                <Description>
                    Inspiire est une entreprise pensée pour le 𝗯𝗶𝗲𝗻-𝗲̂𝘁𝗿𝗲, la
                    quête de 𝗰𝗵𝗮𝗹𝗹𝗲𝗻𝗴𝗲 mais aussi de 𝘀𝗲𝗻𝘀. Si
                    vous désirez rejoindre une communauté
                    passionnée et solidaire, Contactez nous !
                </Description>
            </Profil>
            <ProfilNico>
                <AlignNico>
                    <Info>
                        <p>Nicolas Sappey</p>
                        <p>Co-fondateur</p>
                        <a style={{textDecoration: 'none', color:'inherit'}} href="tel:0786004319"><p>Tel : 07 86 00 43 19</p></a>
                        <a style={{textDecoration: 'none', color:'inherit'}} href='mailto:nsappey@inspiire.fr'><p>Mail : nsappey@inspiire.fr</p></a>
                        <a style={{marginTop: "10px"}} aria-label="Linkedin Nicolas" href="https://www.linkedin.com/in/nicolas-sappey-8228361a/">
                            <img src={Linkedin_Nicolas} height={50} width={50} alt=""/>
                        </a>
                    </Info>
                    <Img className='hover-zoom-patron' src={Nicolas_couleurs} onMouseOver={e => e.currentTarget.src = Nicolas_black}
                    onMouseOut={e => e.currentTarget.src = Nicolas_couleurs} alt=""/>
                </AlignNico>
                <Description style={{maxWidth:"550px"}}>
                    Notre ADN : 𝗧𝗲𝗰𝗵 𝗛𝘂𝗺𝗮𝗻 & 𝗣𝗹𝗮𝗻𝗲𝘁 ! Apporter le
                    meilleur en terme technologique tout en mettant l’Humain
                    et notre Planète au cœur de notre raison d’être.
                </Description>
            </ProfilNico>
        </Fade>
      </Container>
    )
}

const Container = styled.div`
    display: grid;
    padding: 0 6%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 0px;
    margin-top: 100px;

    @media screen and (max-width: 1330px) {
        display: none;
    }
`

const Profil = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const ProfilNico = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10%;
    flex-direction: column;
`

const Align = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 700px) {
        flex-direction: column;
        text-align: center;
    }
`

const AlignNico = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        text-align: center;
    }
`

const Info = styled.div`
    display: flex;
    padding-bottom: 25px;
    justify-content: end;
    flex-direction: column;
    color: white;
    & p{
        font-size: 20px;
        font-weight: 700;
    }
`

const Description = styled.p`
    color: white;
    font-size:20px;
    max-width: 540px;
    @media screen and (max-width: 700px) {
        text-align: center;
    }
`
const Img = styled.img`
    width: 18rem;
    height: 18rem;
`
export default Patron;
