import styled from 'styled-components';
import React from 'react';
import TitleVie from './titre_expertise';

const Containerinterne= styled.div`
    background: linear-gradient(90deg,#4338B4,#7E46AD,#AC50AA,#CF58A8);
    overflow: hidden;
    position: relative;
    margin-top: -100px;
`;

const BlackPolygon = styled.div`
    background: black;
    width: 100%;
    position: absolute;
    height: 100%;
    bottom: -1px;
    clip-path: polygon(85% 91%, 0% 100%, 100% 100%);
    @media screen and (max-width: 970px) {
        clip-path: polygon(82% 97%,0% 100%,100% 100%) ;
    }
`

const Vieinterne = () => {
    return (
        <Containerinterne>
            <TitleVie/>
            <BlackPolygon/>
        </Containerinterne>
    );
};

export default Vieinterne;
