import Main from './main';
import Mentionlegales from '../src/components/mention_legales/mentin_legales.js';

import './App.css';
import './locomotive-scroll.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main}/>
        <Route exact path="/mention-legales/" component={Mentionlegales}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
