import React, {useState} from "react";
// import logo from '../../../assets/logo2.png';
import logo from '../../../assets/logo2.png';
import '../../../App.css';
import { BrowserRouter } from 'react-router-dom';
import '../css/navbar.css';

import { Divide as Hamburger } from 'hamburger-react'

const linksList =[
    {
        title : "Expertises",
        link : "#expertise"
    },
    {
        title : "Raison d'être",
        link : "#raison"
    },
    {
        title : "Identité",
        link : "#identity"
    },
    {
        title : "Valeurs",
        link : "#values"
    },
    {
        title : "Carrière",
        link : "#carrer"
    }
]

const linksListphone =[
    {
        title : "Expertises",
        link : "#expertise"
    },
    {
        title : "Raison d'être",
        link : "#raison"
    },
    {
        title : "Identité",
        link : "#identityphone"
    },
    {
        title : "Valeurs",
        link : "#values"
    },
    {
        title : "Carrière",
        link : "#carrer"
    }
]

function hamburgerClicked(isOpen){
    let item = document.getElementById("block-links-mobile");
    if(isOpen)
    {
        item.classList.remove("slide-in-top");
        item.classList.add("slide-out-top");
        item.style.opacity = "0";
        document.getElementById("navbar").style.zIndex = "0";
    }
    else{
        item.classList.remove("slide-out-top");
        item.classList.add("slide-in-top");
        item.style.visibility = "visible";
        document.getElementById("navbar").style.zIndex = "1";
    }
}

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div id="navbar">
            <BrowserRouter>
                    <div id='block-logo'>
                        <a id="link-logo" aria-label="Logo Inspiire" href='#navbar'><img src={logo} height={49} width={171} alt=""/></a>
                    </div>

                    <div id='block-links-web'>
                        {
                            linksList.map((item)=>
                            {
                                return (
                                    <li style={{listStyleType: "none"}}><a className='link' href={item.link} aria-label={`Scroll to ${item.title}`}> {item.title} </a></li>
                                )
                            })
                        }
                        <li style={{listStyleType: "none"}}><a href='#contact'><button id="button-contact" >Nous contacter</button></a></li>
                    </div>
                    <div id='block-links-mobile'>
                        {
                            linksListphone.map((item)=>
                            {
                                return (
                                    <a className='link' onClick={() => {hamburgerClicked(isOpen);
                                        setOpen();}} href={item.link}> {item.title} </a>
                                )
                            })
                        }
                        <a href='#contact'><button id="button-contact-phone" >Nous contacter</button></a>

                    </div>

                    <div id="block-hamburger" onClick={hamburgerClicked.bind(this, isOpen)}>
                        <Hamburger toggled={isOpen} toggle={setOpen} color="#ffffff"/>
                    </div>

            </BrowserRouter>
        </div>
    );
};


export default Navbar;
