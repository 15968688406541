import styled from 'styled-components';
import Slideshow from './slider_valeurs';
import Titrevaleur from './titre_valeur';
import Logovaleurs from './logo_valeur';
import 'react-slideshow-image/dist/styles.css'

const Containervaleurs= styled.div`
    min-height:  110vh;
`;

const Balise = styled.div`
  width: 1%;
  height: 1%;
  position: sticky;
  border: 3px solid transparent;
`;

const Valeurs = () => {
    return (
        <Containervaleurs>
            <Balise id="values"/>
            <Titrevaleur/>
            <Logovaleurs/>
            <Slideshow/>
        </Containervaleurs>
    );
};

export default Valeurs;
