import styled from 'styled-components';
import React from 'react';
import 'aos/dist/aos.css';
import Fade from 'react-reveal/Fade';

const Titleraison = () => {
    return (
        <div>
            <Fade top duration={2000}>
                <PosTitle>
                    <Texttitle id="raison">NOTRE RAISON D’ÊTRE</Texttitle>
                </PosTitle>
            </Fade>
            <Fade bottom duration={2000}>
                <PosText>
                    <Text>
                    Nous avons la ferme volonté de contribuer à la construction d'un monde meilleur et durable.
                    </Text>
                </PosText>
                <PosText>
                    <Text>
                    C'est pourquoi nous avons fait le choix d’être une <span style={{fontWeight:"bold"}}>société à mission</span> : en plus d’être une société <span style={{fontWeight:"bold"}}>experte en développement Agile & Product Management</span>,
                     nous entendons générer un<span style={{fontWeight:"bold"}}> impact social et environnemental positif</span> dans l’exercice de nos activités.
                    </Text>
                </PosText>
                <PosText>
                    <Text>
                        Nous avons ainsi défini 4 engagements au cœur de notre mission qui sont inscrits dans les statuts de la société :
                    </Text>
                </PosText>
            </Fade>
        </div>
    );
};

const PosTitle = styled.div`
    text-align: center;
    padding: 0 6%;
    padding-top: 10px;
    margin-bottom: 60px;
`;

const PosText = styled.div`
    padding: 0 6%;
    margin-top: 40px;
    max-width: 1100px;
`;

const Texttitle = styled.h1`
    text-decoration: none;
    text-align: justify;
    color: black;
    font-size: 70px;
    font-family: 'Greycliff CF';
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
`;

const Text = styled.h2`
    text-decoration: none;
    text-align: justify;
    color: black;
    font-family: 'Nunito sans';
    font-size: 21px;
    font-weight: 200;
`;

export default Titleraison;