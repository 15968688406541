import Contactpage from './components/contact_page/contact_page';
import Reception from './components/reception/reception';
import Mention from './components/mention/mention';
import Raison from './components/raison_etre/page_raison';
import Expertise from './components/expertises/page_expertise';
import Identités from './components/identités/main.js';
import Valeurs from './components/nos_valeurs/page_valeurs';
import Carriere from './components/gestion_carrière/page_carriere';
import Vieinterne from './components/vie_interne/page_interne';
import Associes from './components/associes/page_associes';
import styled from 'styled-components';
// import Snowfall from 'react-snowfall';

import './App.css';
import './locomotive-scroll.css';

function Main() {
  return (
    <div>
      <WhitePart>
        <Reception/>
        <Expertise/>
      </WhitePart>
        <Raison/>
        <Identités/>
      <Valeurs/>
      <BlackPart>
        <Carriere/>
      </BlackPart>
      <Vieinterne/>
      <Associes/>
      <WhitePart>
        <Contactpage/>
      </WhitePart>
      <BlackPart>
        <Mention/>
      </BlackPart>
    </div>
  );
}

const WhitePart = styled.div`
  background-color: white;
`;

const BlackPart = styled.div`
  background-color: black;
`;

export default Main;
