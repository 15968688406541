import styled, { css } from 'styled-components';
import React from 'react';
import Patron from './patron';
import Fade from 'react-reveal/Fade';
import PatronPhone from './patro_phone';

import {useEffect, useState} from 'react';
import {fixElement} from '../utils/functions/fixElement';

const Pospolygon = styled.div`
  height: 50px;
  margin-top: -20px;
  width: 100%;
  background-color: white;
`

const Containerinterne= styled.div`
    min-height:  180vh;
    background-color: black;
    flex-direction: column;
    width: 100%;
    /* min-height:  140vh; */
    display: flex;
    position: sticky;
`;
const ContainerProfils = styled.div`
  @media screen and (min-width: 1330px) {
    z-index: 2000;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`


const ContainerSVG = styled.div`
  z-index:1010;
  height:100vh;
  @media screen and (max-width: 1330px) {
    display: none;
  }
`


const TitleLeft = styled.div`
    position: relative;
    margin-left: 13%;
    margin-top: 5%;

    @media screen and (max-width: 1300px) {
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
`
const Title = styled.div`
  ${props => props.main && css`
    font-size:55px;
    font-weight: 800;
  `}
  ${props => props.sub && css`
    font-size:20px;
    font-weight: 500;
    margin-top: 25px;
  `}
  ${props => props.color && css`
    color: ${color => props.color};
  `}
  @media screen and (max-width: 1300px) {
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
`
const defautCircleHeight = 1250;

const Associes = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  // windowSize.innerWidth;
  
  useEffect(() => {
    const handleScroll = event => {
      if(windowSize.innerWidth >= 1330)
      {
        const circle_svg = document.getElementById("circle-hole-out");
        const container_interne = document.getElementById("container-interne");
        const container_profil = document.getElementById("container_profil");
        const pos = container_interne.getBoundingClientRect();
        if (pos.top < 0)
        {
            if(pos.top > -1100)
            {
              circle_svg.setAttribute('cy', "32%");
              document.getElementById("div-svg-2").style.position = "absolute";
              // container_profil.style.position = "absolute";
              container_profil.style.zIndex = "1";
              circle_svg.setAttribute('r', defautCircleHeight + pos.top);
              fixElement(true, "div-svg-2");
            }
            else{
              
              document.getElementById("div-svg-2").style.position = null;
              container_profil.style.position = null;
              circle_svg.setAttribute('cy', "80%");
              container_profil.style.zIndex = "2000";
              circle_svg.setAttribute('r', 150);
              Pospolygon.style.backgroundColor= "black";
            }
        }else{
            fixElement(false, "div-svg-2");
        }
        
      }
        
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return (
      <Containerinterne id="container-interne">
            <ContainerProfils id="container_profil">
              <TitleLeft style={{marginTop: "50px"}}>
                <Fade top duration={1000}>
                  <Title color="white" main>LES ASSOCIÉS</Title>
                  <Title color="white" sub>Engagés avant tout pour nos collaborateurs et nos clients mais aussi, à notre petite
                  <br/>échelle, pour des enjeux environnementaux, sociétaux et éthiques !</Title>
                </Fade>
              </TitleLeft>
              <Patron/>
              <PatronPhone/>
            </ContainerProfils>
            <ContainerSVG style={{width: '100%'}} id="div-svg-2">
                <svg style={{width:"100%", height:"100vh"}}>
                <mask id='hole2'>
                <rect width="100vw" height="100%" fill="white"/>
                <circle id="circle-hole-out" r="1250" cx="50vw" cy="32%" fill="black"/>
                </mask>
                <rect width="100vw" height="100%" mask="url(#hole2)" fill='white'/>
                </svg>
              <Pospolygon/>
              </ContainerSVG>
        </Containerinterne>
    );
};
function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
export default Associes;
