import styled from 'styled-components';
import React from "react";
import Montagne from '../../assets/fond_montagne.webp';
import logo from '../../assets/logo2.png';
import { Link } from 'react-router-dom';

const Textmention = () => {
    return (
        <Container>
            <PosImage>
                <Link to="/"><img src={logo} height={69} width={231} alt=""/></Link>
            </PosImage>
            <Postitle>
                <TextTitle>Mentions légales</TextTitle>
            </Postitle>
            <PosText>
                <Text>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs du site www.inspiire.fr les présentes mentions légales.
                    <br/>
                    La connexion et la navigation sur le site www.inspiire.fr parl’utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.
                    <br/>
                    Ces dernières sont accessibles sur le site à la rubrique « Mentions légales ».
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                    ARTICLE 1 : L’ÉDITEUR
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                L’édition du site www.inspiire.fr est assurée par la Société INSPIIRE : SAS au capital de 50 000 euros, immatriculée sous le numéro 918 350 570 RCS Nanterre dont le siège social est situé au 92 avenue Charles de Gaulle 92200 Neuilly-sur-Seine, numéro de téléphone 01 84 20 02 26, adresse e-mail : contact@inspiire.fr.
                <br/>
                N° de TVA intracommunautaire : FR07918350570
                <br/>
                de la publication est Franck Marty, Directeur Général
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                    ARTICLE 2 : L’HÉBERGEUR
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                L’hébergeur du site www.inspiire.fr est la Société OVH, dont le siège social est situé au 2 rue Kellermann 59100 ROUBAIX, avec le numéro de téléphone : 09 55 00 66 33.
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                ARTICLE 3 : DESCRIPTION DES SERVICES FOURNIS
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                Le site www.inspiire.fr a pour objet de fournir une information concernant l’ensemble des activités de la société. Le proprietaire du site s’efforce de fournir sur le site www.inspiire.fr des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. Tous les informations proposéessur le site www.inspiire.fr sont données à titre indicatif, sont non exhaustives, et sont susceptibles d’évoluer. Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                ARTICLE 4 : ACCÈS AU SITE
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.
                En cas de modification, interruption ou suspension des services le site www.inspiire.fr ne saurait être tenu responsable.
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                ARTICLE 5 : LIMITATIONS CONTRACTUELLES SUR LES DONNÉES TECHNIQUES
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                Le site utilise la technologie React.
                <br/>
                Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                ARTICLE 6 : COLLECTE DES DONNÉES
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                Le site assure à l’utilisateur, qu’en sa qualité de responsable de traitements, INSPIIRE procède à une collecte et un traitement d’informations personnelles dans le respect des obligations légales liées à la protection des données personnelles, notamment RGPD et la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, telles que modifiées.
                En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l’utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition de ses données personnelles. L’utilisateur peut exercer ce droit :
                <br/>
                • par mail à l’adresse email contact@inspiire.fr
                <br/>
                • par voie postale au 92 avenue Charles de Gaulle 92200 Neuilly-sur-Seine
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                ARTICLE 7 : COOKIES
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.
                <br/>
                En naviguant sur le site, il les accepte.
                <br/>
                Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet. L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
                </Text>
            </PosText>
            <Postitlesecond>
                <TextTitle>
                ARTICLE 8 : PROPRIÉTÉ INTELLECTUELLE
                </TextTitle>
            </Postitlesecond>
            <PosText>
                <Text>
                Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site www.inspiire.fr, sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
                </Text>
            </PosText>
        </Container>
    );
};

const Container = styled.div`
    min-height: 100vh;
    background-image: url(${Montagne});
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
`

const Postitle = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 5%;
`

const PosImage = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 5%;
`

const Postitlesecond = styled.div`
    padding-top: 5%;
    margin-left: 5%;
`

const PosText = styled.div`
    padding-top: 15px;
    margin-right: 5%;
    margin-left: 5%;
`


const TextTitle = styled.text`
    font-weight:${ props => props.weight };
    margin-top: 2%;
    text-decoration: none;
    text-align: justify;
    font-size: 35px;
    color: black;
    text-align: center;
    font-size: ${props => props.fontSize};
    @media screen and (max-width: 800px) {
        font-size: 30px;
    }
    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`;

const Text = styled.text`
    font-weight:${ props => props.weight };
    margin-top: 2%;
    font-size: 20px;
    color: white;
    text-decoration: none;
    text-align: justify;
    text-align: center;
    font-size: ${props => props.fontSize};
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
`;

export default Textmention;
